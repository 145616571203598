<template>
<div class="layout--full-page">
    <vs-progress indeterminate color="primary" v-show="isProgressing"></vs-progress>
    <router-view></router-view>
</div>
</template>

<script>
export default {
    computed: {
        isProgressing() {
            return this.$store.state.isProgressing
        }
    }
}
</script>
